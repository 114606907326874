 .List li {
     font-size: 19px;
     font-weight: 500;
 }

 .List li::marker {
     color: #6D12FF;
     font-size: 30px;
     font-weight: 500;
     margin-top: 10px;
 }
 .restaurantOnboardWrapper{
    gap: 5px;
    margin-left: 450px;
    border-radius: 20px;
    border: 2px solid #6D12FF;
    padding:0px 30px;
    margin-top: 20px;
    margin-bottom: 20px;
 }
 .restaurantOnboardWrapper input{
    width: 450px;
    height: 35px;
    border-radius: 8px;
    padding-left: 20px;
    border: none;
 }
 .restaurantOnboardWrapper input::placeholder{
    color: #BEBEBE;
font-size: 14px;
font-weight: 300;
 }
.restaurantlabel{
    font-size: 14px;
    color: #FFF;
    font-weight: 500;
}
.restaurantbutton1{
    width:140px ;
    height: 35px;
    border-radius: 7px;
    background: #fff;
    font-size: 16px;
    font-weight: 600;
    color: #6D12FF;
    border: none;
}
.restaurantbutton2{
    width:290px ;
    height: 35px;
    margin-left: 20px;
    border-radius: 7px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background:#5851EA;
    border: none;
}

 .ordering {
     width: 500px;
 }

 .restaurantImg1 {
     height: 210px;
     width: 300px;
 }

 .restaurantImg2 {
     height: 210px;
     width: 300px;
     margin-top: 15px;
 }

 .restaurantImg3 {
     height: 480px;
     width: 310px;
     margin-left: 10px;
 }

 .stepsWrapper {
    margin-top: -70px;
     width: 80vw;
     margin-left: auto;
     margin-right: auto;
 }

 .howItWorks {
     font-size: 45px;
     font-weight: 600;
     margin-top: 100px;
 }

 .steps {
     text-align: center;
     margin-top: 60px;
     width: 400px;
 }

 .step {
     font-size: 140px;
 }

 .stepDesc {
     font-size: 14px;
     font-weight: 500;
     color: #4E4E4E;
 }

 .arrow {
     height: 130px;
     width: 250px;
     margin-bottom: 120px;
 }

 

 @media screen and (max-width:800px) {
     .stepsWrapper {
         flex-direction: column;
     }

     .ordering {
         padding: 10px;
     }

     .ordering>p {
         font-size: 30px;
     }

     .restaurantImg1 {
         width: 160px;
         height: 110px;
     }

     .restaurantImg2 {
         width: 160px;
         height: 110px;
     }

     .restaurantImg3 {
         width: 170px;
         height: 260px;
     }
     /* .RestaurantOnboard{
        background-size: contain;
     } */
     .restaurantOnboardWrapper{
        padding: 0 !important;
        margin-left: 0px;
        /* margin-right: 0; */
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: none;
     }
     .restaurantOnboardWrapper h1{
        margin-top: 0 !important;
     }
     .restaurantOnboardWrapper div{
        margin-bottom: 0 !important;
     }
     .restaurantOnboardWrapper span{
        font-size: 18px !important;
     }
     .restaurantOnboardWrapper input{
        width: 340px;
     }
     .restaurantbutton1{
        width: 120px;
     }
     .restaurantbutton2{
        width: 200px;
     }
     .howItWorks {
         font-size: 35px;
         margin-top: 50px;
     }
     .howItWorks img {
        padding: 10px;
        /* border: 2px solid black; */
     }
     .line-rt{
        position: relative;
        left: 70px;
     }

     .steps {
         margin-top: 10px;
         width: 350px;
     }
     #step1{
        margin-top: 80px;
     }
     #step2{
        margin-top: 30px !important;
     }
     #step3{
        margin-top: 30px !important;
     }

     .step {
         font-size: 100px;
     }
     .arrow {
         height: 120px;
         width: 130px;
         margin-bottom: -30px;
         margin-top: 30px;
         transform: rotate(90deg);
     }
 }
 @media screen and (max-width:600px){
   .ordering > .Res-Para1{
  
    font-size: 30px !important;
   }
 }