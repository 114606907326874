@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
}
.home-page {
  max-width: 100%;
  height: auto;
  overflow-x: hidden;
  overflow-y: hidden;
}
.frame1 {
  max-width: 100%;
  height: 850px;
  background-size: cover;
  background-position: center;
  /* margin-bottom: 80px; */
}

.background-image {
  width: 100%;
  height: 850px;
  background-image: url(../Assets/UmlaWebsite/Frame1.png);
  filter: brightness(50%);
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  filter: brightness(100%);
  margin-top: -5rem;
}

.title {
  margin-top: 25rem;

  color: white;
  font-family: Cirka;
  font-size: 42px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0px;
  text-align: center;
}

.Offer {
  font-size: 1.5rem;
  color: white;
  margin-top: 2rem;
  display: flex;
  align-items: center;
}
.dotimg {
  width: 6px;
  height: 6px;
  margin: 10px;
  margin-bottom: 0.5rem;
}

.button {
  border: none;
  font-size: 1.2rem;
  padding: 10px 20px;
  background: linear-gradient(90.45deg, #515ada 14.12%, #efd5ff 99.78%);
  width: 301px;
  height: 58px;
  padding: 14px, 70px, 14px, 70px;
  border-radius: 50px;
  gap: 10px;
  margin-top: 5rem;
  color: white;
}

.store-link img {
  width: 222px;
  height: 68px;
  margin-left: 2.5rem;

  margin-top: 3rem;
}

.Social-media-links {
  margin-top: 25px;
  margin-left: 20px;
}

.Social-media-links a {
  margin-right: 20px;
}

.background-img2 {
  background-image: url(../Assets/UmlaWebsite/bg1.png);
  max-width: 100%;
  height: 600px;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.card-container {
  max-width: 100%;
  height: 282px;
  background: linear-gradient(241.32deg, #ffcf25 0.45%, #ff2525 100%);
}
.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fr2-img {
  margin-bottom: 3.8rem;
  position: relative;

  left: 15rem;
  z-index: 1;
}
.text {
  margin-bottom: 6rem;
}
.text p {
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  align-items: center;
  margin-top: 0px;
  /* margin-bottom: 0px; */
}
.text span {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  align-items: center;
  color: #515ada;
  margin-left: 20px;
}

.card1 {
  width: 220px;
  height: 220px;
}
.card1-img {
  position: relative;
  left: -2rem;
  top: -5rem;
  max-width: 220px;
  height: 220px;
  z-index: 1;
}
.card2 {
  width: 220px;
  height: 220px;
}
.card2-img {
  position: relative;
  width: 220px;
  height: 220px;
  top: 3rem;
  left: -8rem;
  z-index: 1;
}
.png-img1 {
  position: relative;
  top: 5rem;
  left: 15rem;
}
.png-img2 {
  position: relative;
  top: -4rem;
  left: -10rem;
}
.png-french {
  position: relative;
  top: -7rem;
  left: -15rem;
}

/* frame3 started here */
.frame3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
}
.card-container2 {
  background: linear-gradient(241.32deg, #ff25cf 0.45%, #ff2525 100%);
  width: 100%;
  height: 200px;
  margin-top: 6rem;
}
.frame3 p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: -6rem;
}
.frame3 span {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #515ada;
}
.card-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.fr3-img {
  margin-top: -4rem;
  margin-left: 10px;
}
.group1 {
  position: relative;
  top: -2rem;
  left: 5.5rem;
}
.group2 {
  position: relative;
  top: -4rem;
  left: -5rem;
}
.interest-png {
  position: relative;
  left: 5rem;
  top: -15rem;
}
.like-png {
  position: relative;
  top: -2rem;
  left: 1rem;
  z-index: 2;
}
.MeetWrapper {
  margin-top: 60px;
}
.MeetWrapper p {
  font-family: Poppins;
  font-size: 60px;
  font-style: italic;
  font-weight: 700;
  line-height: 5px;
}
.MeetWrapper span {
  font-family: Poppins;
  font-size: 60px;
  font-style: italic;
  font-weight: 700;
  line-height: 20px;
}

.Meet1 {
  opacity: 1;

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: #fff;
}

.Meet2 {
  opacity: 0.5;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #d6e346;
}

/* frame4 css start here */
.frame4{
  margin-top: 2rem;
}
.SlidingVertical {
	display: inline;
	text-indent: 5px;
  overflow-x: hidden;

}
.SlidingVertical span {
	animation: topToBottom 12.5s linear infinite 0s ;
	-ms-animation: topToBottom 12.5s linear infinite 0s ;
	-webkit-animation: topToBottom 12.5s linear infinite 0s ;
	color: #6d12ff;
	opacity: 0;
	overflow: hidden;
	position: absolute;
  font-size: 16px;
  margin-top: -6px;
  
 
}
.SlidingVertical span:nth-child(2) {
	animation-delay: 2.5s;
	-ms-animation-delay: 2.5s;
	-webkit-animation-delay: 2.5s;
  
}
.SlidingVertical span:nth-child(3) {
	animation-delay: 5s;
	-ms-animation-delay: 5s;
	-webkit-animation-delay: 5s;
}
.SlidingVertical span:nth-child(4) {
	animation-delay: 7.5s;
	-ms-animation-delay: 7.5s;
	-webkit-animation-delay: 7.5s;
}
.SlidingVertical span:nth-child(5) {
	animation-delay: 10s;
	-ms-animation-delay: 10s;
	-webkit-animation-delay: 10s;
} 



.frame4 p {
  
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: -18rem;
  
}
.frame4 span {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #515ada;
  margin-top: 2rem;
}
.card-container3 {
  background: linear-gradient(241.32deg, #ff8025 0.45%, #ff2525 100%);
  width: 100%;
  height: 300px;
  margin-top: 10px;
}

.card-container {
  position: relative;
  display: flex;

  padding: 20px;
  width: 100%;
}

.img-container {
  width: 100%;
}
.fr4-img {
  width: 100%;
  margin-top: 20px;
}
.frame5 {
  margin-top: 30px;
}

.fr5-para {
  margin-top: -14rem;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;

}
.frame5 p {
  
  margin-top: -14.3rem;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}
.restaurant {
  background-image: url(../Assets/UmlaWebsite/restaurant.jpg);
  background-size: cover;
  width: 100%;
  height: 129px;
  margin-top: 2rem;
  /* opacity: 0.8; */
  filter: brightness(20%);
}
.bg {
  margin-top: -5rem;
  width: 100%;
  height: 192px;
  background: linear-gradient(256.41deg, #2100ef 0.82%, #ff8412 97.19%);
}
.mockup-img {
  filter: brightness(100%);
  position: relative;
  top: -12rem;
  height: 243px;
}
.right p {
  font-family: Cirka;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #6d12ff;
}
.res-content {
  display: flex;
  justify-content: space-between;
}
.right span {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  position: relative;
  top: -8px;
}
.res-text {
  position: relative;
  top: 8rem;
  left: -1rem;
}
.right button {
  position: relative;
  top: 8rem;
  background: #bb90ff;
  color: #fff;
  padding: 10px;
  font-size: 10px;
  border-radius: 10px;
}
.background-img3 {
  background-image: url(../Assets/UmlaWebsite/bg1.png);
  max-width: 100%;

  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  margin: 0;
}

@media screen and (max-width: 390px) {
  .group1 {
    margin-left: -10px;
  }
  .interest-png {
    position: relative;
    top: -12.5rem;
    left: 4rem;
  }
  .group2 {
    margin-left: 10px;
  }
  .like-png {
    position: relative;
    left: 5px;
  }
  .MeetWrapper p {
    font-size: 38px;
  }
  .MeetWrapper span {
    font-size: 38px;
  }
  .frame4 p {
    margin-top: -18rem;
  }
  .right p {
    margin-left: 20px;
    font-size: 12px;
  }
  .right span {
    margin-left: 20px;
    font-size: 6px;
  }
  .right button {
    font-size: 6px;
  }
  .frame1 {
    width: 100%;
    height: 700px;
  }
  .background-image {
    width: 100%;
    height: 700px;
  }
  .frame5 {

    margin-top: 10px;
  }
  .card-container3{
  
    width: 100%;
    height: 280px;

  }
  .frame4{
    margin-top: 30px;
  }
  
 
}
