.blogPageWrapper {
    margin: 50px;
}

.blogPage {
    background: #EBDEFF;
    border-radius: 50px;
    width: 60vw;
    padding: 40px;
}

.blogText {
    white-space: pre-wrap; /* Preserves whitespace and line breaks */
    font-size: 16px; /* Adjust to your preferred size */
    line-height: 1.6; /* Adjust line height for readability */
}

.blogImage {
    width: 55vw;
    margin-top: 20px;
    /* margin-left: 25px; */
}

.blogText {
    font-size: 18px;
    margin-top: 30px;
    text-align: left;
    width: 50vw;
    line-height: 30px;
}

.author {
    background: #EBDEFF;
    border-radius: 18px;
    padding: 15px 70px 15px 15px;
    line-height: 10px;
}

.suggestions {
    background: #EBDEFF;
    border-radius: 18px;
    width: 310px;
    padding: 15px 50px 30px 15px;
}
.search{
     width: 310px;
     border-radius:23px;
     background:#EBDEFF;
}
.search img{
     width: 310px;
}

@media screen and (max-width:600px) {
    .blogPageWrapper {
        margin: 15px;
    }

    .blogPage {
        width: 95vw;
        padding: 15px;
        border-radius: 25px;
    }

    .blogImage {
        width: 85vw;
    }

    .blogText {
        font-size: 16px;
        width: 85vw;
    }

    .author {
        margin-top: 15px;
        width: 93vw;
        justify-content: center !important;
    }
    .suggestions{
        width: 93vw;
    }
    .search{
        width: 93vw;
    }
    .search img{
        width: 93vw;
    }
}