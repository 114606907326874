/* .scroll{
	scroll-snap-type: y mandatory;
} */
/* .hero{
	scroll-snap-align: start;
	transition: 1s ease-in-out;
}
.buyAndOffer{
	scroll-snap-align: start;
	transition: 1s ease-in-out;
}
.matchAndConnect{
	scroll-snap-align: start;
	transition: 1s ease-in-out;
}
.Meet{
	scroll-snap-align: start;
	transition: 1s ease-in-out;
} */
#heroImg1 {
  height: 90vh;
}

#heroImg2 {
  height: 90vh;
}

.wrapper {
  background-color: #ffffff;
  font-family: "Raleway", sans-serif;
  padding: 10px 10px;
  border-radius: 20px;
  width: 130px;
  height: 60px;
  position: relative;
  top: 300px;
  left: 25px;
}
.sentence {
  text-transform: uppercase;
  font-weight: 800;
  color: #6d12ff;
  font-size: 12px;
  text-align: left;
}
.slidingVertical {
  display: inline;
  text-indent: 5px;
}
.slidingVertical span {
  animation: topToBottom 12.5s linear infinite 0s;
  -ms-animation: topToBottom 12.5s linear infinite 0s;
  -webkit-animation: topToBottom 12.5s linear infinite 0s;
  color: #6d12ff;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.slidingVertical span:nth-child(2) {
  animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}
.slidingVertical span:nth-child(3) {
  animation-delay: 5s;
  -ms-animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
.slidingVertical span:nth-child(4) {
  animation-delay: 7.5s;
  -ms-animation-delay: 7.5s;
  -webkit-animation-delay: 7.5s;
}
.slidingVertical span:nth-child(5) {
  animation-delay: 10s;
  -ms-animation-delay: 10s;
  -webkit-animation-delay: 10s;
}

/*topToBottom Animation*/
@keyframes topToBottom {
  /* -moz- */
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -moz-transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
  30% {
    opacity: 1;
    -moz-transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes topToBottom {
  /* -webkit- */
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes topToBottom {
  /* -ms- */
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -ms-transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -ms-transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.heroText {
  color: #21383e;
  font-size: 30px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
}

.float-box2 {
  background: #6d12ff;
  color: #ffffff;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
  font-size: 45px;
  font-weight: 800;
  letter-spacing: 8px;
  text-align: center;
  border-radius: 15px 15px 0px 0px;
  width: 380px;
  height: 70px;
  transform-style: preserve-3d;
  transform-origin: left;
  transform: perspective(100px);

  animation: floating2 3s linear;
}
@keyframes floating2 {
  0% {
    transform: translate(0%, 0%);
  }
  /* 25%{
        transform: translate(0%, -35%);
	} */
  50% {
    transform: translate(0%, 100%);
  }
  /* 75%{
        transform: translate(0%, 35%);
	} */
  100% {
    transform: translate(0%, 0%);
  }
}

.float-box {
  font-size: 55px;
  font-weight: 800;
  width: 450px;
  height: 70px;
  color: #6d12ff;
  letter-spacing: 8px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border: 1px solid #6d12ff;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -2px -4px 10px rgba(0, 0, 0, 0.4);
  text-align: center;

  transform-style: preserve-3d;
  transform-origin: left;
  transform: perspective(100px);

  animation: floating 3s linear;
}
.float {
  margin-top: -11px;
}

@keyframes floating {
  0% {
    transform: translate(0%, 0%);
  }
  /* 25%{
        transform: translate(0%, -35%);
	} */
  50% {
    transform: translate(0%, -70%);
  }
  /* 75%{
        transform: translate(0%, 35%);
	} */
  100% {
    transform: translate(0%, 70%);
  }
}

.float-box3 {
  background: #6d12ff;
  color: #ffffff;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
  font-size: 45px;
  font-weight: 800;
  letter-spacing: 8px;
  text-align: center;
  border-radius: 0px 0px 15px 15px;
  width: 380px;
  height: 70px;
  margin-bottom: 50px;

  transform-style: preserve-3d;
  transform-origin: left;
  transform: perspective(100px);

  animation: floating3 3s linear;
  animation-delay: 1.5s;
}
@keyframes floating3 {
  0% {
    transform: translate(0%, 0%);
  }
  /* 25%{
        transform: translate(0%, -35%);
	} */
  50% {
    transform: translate(0%, -140%);
  }
  /* 75%{
        transform: translate(0%, 35%);
	} */
  100% {
    transform: translate(0%, 0%);
  }
}
.exploreText > p {
  color: #6d12ff;
}
.exploreText {
  background: #ffffff;
  /* border: 2px solid #6d12ff; */
  padding: 15px 15px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 700;
}
.formInputs {
  width: 150px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  padding: 5px 13px;
  box-shadow: 0px 30px 80px rgba(47, 65, 88, 0.2);
  border-radius: 7px;
}
.formButton {
  width: 120px;
  color: #ffffff;
  font-weight: 600;
  background: #6d12ff;
  padding: 6px;
  border-radius: 7px;
  border: none;
}

.Shadow {
  background: linear-gradient(
    91.36deg,
    #6d12ff 35.21%,
    rgba(109, 18, 255, 0) 132.84%
  );
  color: white;
  /* margin-right: auto;
	margin-left: auto; */
  margin-right: -60px;
  height: 73vh;
}

.Text {
  margin-right: -70px;
  transform: rotate(-90deg);
  font-size: 60px;
  line-height: 20px;
  font-weight: 700;
}

.mockup1Parent {
  height: 91vh;
  background-image: url(../images/dots.svg);
  background-repeat: no-repeat;
  background-position: right;
}

.mockup1 {
  height: 75vh;
}

.hero3 {
  height: 91vh;
}

.hero4 {
  height: 91vh;
}
/* .Match{
	width: 52.5vw;
} */

.Shadow2 {
  z-index: -1;
  background: linear-gradient(270deg, #6d12ff 0%, #d9d9d9 123.99%);
  color: white;
  margin-left: -25px;
  height: 73vh;
}

.Text22 {
  margin-left: -95px;
  transform: rotate(90deg);
  font-size: 45px;
  line-height: 15px;
  font-weight: 700;
}

.mockup2Parent {
  height: 91vh;
  background-image: url(../images/dots.svg);
  background-repeat: no-repeat;
  background-position: left;
}

.mockup2 {
  height: 75vh;
}

.Text1 {
  opacity: 0.1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.Text2 {
  opacity: 0.3;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.Text3 {
  opacity: 0.5;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.Text4 {
  opacity: 0.7;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.Text5 {
  opacity: 0.9;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.hero5 {
  height: 91vh;
}

.linearBackground1 {
  background: linear-gradient(
    90deg,
    #6d12ff 10.72%,
    rgba(255, 255, 255, 0.61) 124.09%
  );
  filter: blur(20px);
  height: 89vh;
  width: 10vw;
  margin-left: -100px;
  z-index: -1;
  opacity: 0.8;
}

.linearBackground2 {
  background: linear-gradient(
    90deg,
    #6d12ff 10.72%,
    rgba(255, 255, 255, 0.61) 124.09%
  );
  transform: rotate(-180deg);
  filter: blur(20px);
  height: 89vh;
  width: 10vw;
  margin-right: -100px;
  z-index: -1;
  opacity: 0.8;
}

.mockup3 {
  height: 70vh;
}

.hero6 {
  height: 91vh;
}

.meetWrapper {
  line-height: 10px;
  font-size: 40px;
  font-weight: 700;
}

.meet1 {
  opacity: 0.3;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #6d12ff;
}

.meet2 {
  opacity: 0.5;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #6d12ff;
}

.meet3 {
  opacity: 0.8;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #6d12ff;
}

.meet4 {
  color: #6d12ff;
}

.Card {
  width: 20rem;
  border: none;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.Card:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.Btn {
  margin-top: 25px;
  font-size: 14px;
  color: white;
  font-weight: 600;
  background: #6d12ff;
  border: 2px solid #6d12ff;
  padding: 10px 23px;
  border-radius: 5px;
}

.Btn:hover {
  transition: 0.3s ease-in-out;
  color: #6d12ff;
  background: white;
}

.Btn2 {
  font-size: 12px;
  font-weight: 600;
  background: #6d12ff;
  border: 2px solid #6d12ff;
  border-radius: 8px;
  color: #ffffff;
  padding: 8px 8px;
}

.Btn2:hover {
  transition: 0.3s ease;
  color: #6d12ff;
  background: white;
}

.downloadAppWrapper {
  background: #6d12ff;
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;
}

.registerRestaurantWrapper {
  margin-left: 250px;
}

.registerRestaurant {
  font-size: 50px;
  color: white;
  font-weight: 700;
}

.getCustomers {
  font-size: 25px;
  color: white;
  font-weight: 500;
}

.downloadApp {
  margin-right: 500px;
}

.mockup4Parent {
  position: absolute;
  left: 150px;
}

.mockup4 {
  height: 65vh;
}

.mockup5Parent {
  position: absolute;
  right: 100px;
}

.mockup5 {
  height: 65vh;
}

@media screen and (min-width: 1800px) {
  .wrapper {
    top: 600px;
    left: 50px;
  }
}
@media screen and (max-width: 1580px) {
  .Text {
    font-size: 50px;
  }
  .Text22 {
    font-size: 40px;
  }
}
@media screen and (max-width: 1080px) {
  .registerRestaurantWrapper {
    margin-right: -4rem;
  }
  .registerRestaurant {
    font-size: 40px;
  }
  .mockup4Parent {
    margin-left: -4rem;
  }
  .buyAndOffer {
    /* border: 2px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Text {
    font-size: 42px;
    padding: 20px;
  }
  .hero3 {
    width: 100%;
    height: 380px;
    margin-top: -50px;
  }
  .wrapper {
    position: relative;
    top: 13.5rem;
    left: 10px;
  }
  .hero4 {
    width: 100%;
    height: 380px;
  }
  .Text22 {
    font-size: 30px;
    padding: 20px;
    line-height: 15px;
  }
  .mockup2Parent {
    padding: 10px;
    /* margin-right: -10px; */
  }
}
@media screen and (max-width: 960px) {
  .Text {
    font-size: 38px;
    padding: 0px;
    margin-right: -25px;
  }
  .mockup1 {
    margin-left: 10px;
  }

  .mockup1Parent {
    margin-left: 20px;
  }
}
@media screen and (max-width: 880px) {
  .registerRestaurant {
    font-size: 35px;
  }
  .downloadApp {
    margin-left: 5rem;
  }
  .Text {
    font-size: 30px;
    padding: 0px;
    /* margin-right: -25px; */
  }
  .Text22 {
    font-size: 28px;
  }
}
@media screen and (max-width: 800px) {
  .hero {
    margin-top: 0 !important;
    height: 80vh;
    background-image: url(../images/mobileHeroBackground.jpg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .heroMid {
    width: 320px;
  }

  #heroImg1 {
    display: none;
  }
  #heroImg2 {
    display: none;
  }

  .heroText {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 10px;
    width: 320px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;

    margin-top: -30px;
    margin-left: -10px;
  }
  .heroText p {
    color: white !important;
  }
  .offerMatchMeet {
    width: 300px;
    height: auto;
    margin-top: 5rem;
  }

  .float-box2 {
    width: 190px;
    height: 35px;
    -webkit-text-stroke-width: 1px;
    letter-spacing: 10px !important;
    font-size: 20px;
    -webkit-text-stroke-width: 1px;
    letter-spacing: 3px;
    border-radius: 10px 10px 0px 0px;
  }

  .float-box3 {
    width: 190px;
    height: 35px;
    -webkit-text-stroke-width: 1px;
    letter-spacing: 10px !important;
    font-size: 20px;
    -webkit-text-stroke-width: 1px;
    letter-spacing: 3px;
    border-radius: 0px 0px 10px 10px;
  }

  .float-box {
    border-radius: 10px;
    width: 230px;
    height: 35px;
    letter-spacing: 10px;
    font-size: 22px;
  }
  .float {
    margin-top: -1px;
  }
  .explore {
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .exploreText {
    text-align: center;
    border: none;
    width: 350px;
    color: #6d12ff;
    background: #d9d9d9;
    padding: 8px;
    border-radius: 7px;
    font-size: 10px;
    font-weight: 700;
    margin-left: -18px;
  }
  .formInputs {
    width: 94px;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    padding: 2px 10px;
    box-shadow: 0px 30px 80px rgba(47, 65, 88, 0.2);
    border-radius: 7px;
  }
  .formButton {
    width: 95px;
    color: #ffffff;
    font-weight: 600;
    background: #6d12ff;
    padding: 3px;
    border-radius: 7px;
    border: none;
  }

  .Btn2 {
    padding: 13px;
    font-size: 14px;
  }

  .buyAndOffer {
    margin-top: -70px;
    align-items: center;
    justify-content: space-around !important;
    gap: 0 !important;
  }
  .buyAndOfferPhone {
    margin-left: -4rem;
    /* position: relative;
    left: -50px; */
  }
  .Shadow {
    background: none;
    margin-right: -152px;

    width: 26vw;
  }

  .Text {
    font-size: 21px;
    line-height: 8px;
    transform: none;
    color: #6d12ff;
    position: relative;
    left: 185px;
    bottom: 110px;
  }

  .Text1 {
    display: none;
  }

  .Text2 {
    display: none;
  }

  .Text3 {
    display: none;
  }

  .Text4 {
    display: none;
  }

  .Text5 {
    display: none;
  }
  .Text6 {
    display: none;
  }

  .mockup1Parent {
    background-size: contain !important;
    z-index: -2;
    height: 350px;
    position: relative;
    left: 10px;
    width: 100%;
  }
  .buyAndOffer {
    margin-top: 0px;
  }

  .mockup1 {
    height: 290px;
    width: 100%;
    padding-left: 15px;
    position: relative;
    right: -10px;
  }

  .hero3 {
    border-top: 3px solid #6d12ff;
    border-bottom: 3px solid #6d12ff;
    height: 230px;
    width: 100%;
    margin-left: 0.5rem;
  }
  .wrapper {
    z-index: 2;
    top: 155px;
    left: 15px;
    width: 61px;
    height: 25px;
    padding: 3px 5px;
    border-radius: 7px;
  }
  .sentence {
    font-size: 6px;
  }
  .wrapper-img {
    margin-bottom: 3rem;
    margin-left: 50px;
  }
  .matchAndConnect {
    gap: 0 !important;
    margin-top: -150px !important;
  }

  .Shadow2 {
    background: none;
    width: 23vw;
    margin-left: -95px;
  }

  .Text22 {
    font-size: 17px;
    width: 250px;
    transform: none;
    color: #6d12ff;

    position: relative;
    right: 175px;
    bottom: 120px;
  }

  .mockup2Parent {
    height: 350px;

    background-position: right !important;
    background-size: contain !important;
    margin-left: -20px;
    margin-right: 0 !important;
  }

  .mockup2 {
    height: 290px;
    width: 150px;
    position: relative;
    right: -10px;
  }

  .hero4 {
    border-top: 3px solid #6d12ff;
    border-bottom: 3px solid #6d12ff;
    height: 230px;
    width: 100%;
    margin-left: 60px;
  }
  .Meet {
    justify-content: center !important;
    background-image: url(../images/meetMobileBackground.jpg);
    background-position: center;
    background-repeat: no-repeat;
    height: 70vh;
    margin-top: -30px;
  }

  .meet1 {
    display: none;
  }
  .meet2 {
    display: none;
  }
  .meet3 {
    display: none;
  }
  .meet4 {
    color: #ffffff;
    font-size: 50px;
    margin-bottom: 2rem;
  }
  .blogWrapper {
    margin-top: -30px;
  }

  .hero5 {
    display: none;
  }

  .hero6 {
    display: none;
  }
  .linearBackground1 {
    display: none;
  }
  .linearBackground2 {
    display: none;
  }
  .mockup3Parent {
    position: relative;
    top: 100px;
  }

  .mockup3 {
    height: 420px;

    width: 210px;
    margin-top: 10px;
  }

  .mockup4 {
    display: none;
  }

  .mockup5 {
    display: none;
  }

  .blogTextDesc {
    width: 90vw !important;
  }

  .Restaurant {
    height: 50vh;
    margin-top: 130px !important;
    background-position: center !important;
  }
  .registerRestaurantWrapper {
    padding: 0 !important;
    margin-left: -4.5rem;

    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .registerRestaurant {
    text-align: center;
    width: 300px !important;
    font-size: 21px;
  }
  .getCustomers {
    font-size: 20px;
  }
  .downloadApp {
    margin-right: 80px;
    padding: 50px 0 !important;
  }
  .downloadAppNow {
    font-size: 28px !important;
    text-align: center;
  }
  .downloadAppText {
    text-align: center;
    width: 95vw;
  }
  .downloadButtons {
    display: flex !important;
  }
  .DownloadButton1 {
    margin-top: 2px;
  }
  .Match {
    margin-left: auto;
  }
  .slidingVertical {
    text-indent: 2px;
  }
}
