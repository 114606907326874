.Link a{
color:white;
text-decoration: none;
margin-left:25px;
}
.Icons{
    width:33vw;
}
.Icons img{
    margin-right: 15px;
}
.offer{
    font-size:30px;
    font-weight:700;
}
.logoFooter{
    height:90px;
    width:190px; 
}
.Logo{
    margin-left: 5rem;
   
    
}
.offer{
 
   
}
@media screen and (max-width:1340px) {
    .Link a{
        margin-left: 20px;
        font-size: 14px;
    }

}
@media screen and (max-width:1180px) {
    .Link a{
        margin-left: 15px;
        font-size: 12px;
    }

}
@media screen and (max-width:1000px) {
    .Link a{
        margin-left: 10px;
        font-size: 10px;
    }

}
@media screen and (max-width:800px) {
    
    .Link{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .Link a{
        margin-left: 0;
        font-size: 14px;
    }
    .Icons{
        display: flex;
        flex-direction: column;
        gap:15px;
        margin-right: -20px;
    }
    .Logo{
        margin-right: 20px;
    }
    .logoFooter{
        margin-left: 40px;
        height: 50px;
        width: 100px;
    }
    .offer{
        width: 160px;
        margin-top: 10px;
        margin-left: 10px;
        font-size: 15px;
    }
    .dotimg{
        width: 7px;
        height: 7px;
    }
}
 @media (max-width: 480px) {
    .footer-container {
      padding: 10px;
      text-align: center;
    }
  
    .Link {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: -20px;
      margin-left: -20px;
    }
    .Logo {
      display: block;
      /* margin-bottom: 20px; */
      margin-top: -10px;
      margin-left: 10px;
    }
    .Icons a{
      /* margin: 5px; */
      margin-top: 10px;
    }
    .Icons {
        margin-top: 40px;
    }
  }
  