.blog {
	width: 22rem;
	border: none;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
	transition: transform .2s;
}

.blog:hover {
	transform: scale(1.05);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}


@media(max-width:620px){
	th{
		font-size: 14px;
		line-height: 20px;
	}
	td{
		font-size: 12px;
		line-height: 20px;

	}
	.privacy-table{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	.text-lg{
		margin-top: 10px;
	  }
	  .text-lg h1{
		font-size: 18px;
	  }
	  .text-lg p {
		font-size: 16px;
	  }

}

@media(max-width:420px){
	th{
		font-size: 12px;
	}
	td{
		font-size: 10px;
		line-height: 15px;
	}
	.privacy-table{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	.text-lg h1{
		font-size: 14px;
	  }
	  .text-lg p {
		font-size: 12px;
	  }

}