.articlesDesc {
    text-align: center;
    width: 600px;
    color: #6C6C72;
    font-size: 18px;
}

/* .blog {
    background: #EBDEFF;
    border-radius: 50px;
    width: 60vw;
} */

@media screen and (max-width:600px) {
    .articlesDesc {
        width: 90vw;
        font-size: 16px;
    }

}